var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"devis-commandes-table"},[_c('TableClassic',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"checkboxes":"","route-name":"listes_devis_devis_item","active-sort":_vm.activeSort},on:{"sort":function($event){return _vm.$emit('sort', $event)}},scopedSlots:_vm._u([{key:"libelle",fn:function({ value, }){return [_vm._v(" "+_vm._s(value.libelle)+" ")]}},{key:"numero",fn:function({ value, }){return [_vm._v(" "+_vm._s(value.id_4d)+" ")]}},{key:"date",fn:function({ value, }){return [_vm._v(" "+_vm._s(_vm._f("moment")(value.date,_vm.$t("global.format-date")))+" ")]}},{key:"quantite",fn:function({ value, }){return [_vm._v(" "+_vm._s(value.total_quantite)+" ")]}},{key:"statut",fn:function({ value, }){return [_c('TimelineLabel',{attrs:{"tooltip":{ title: _vm.defineStatusInfos(value).tooltip, placement: 'top', },"label":_vm.defineStatusInfos(value).label,"status":_vm.defineStatusInfos(value).status,"counter-label":_vm.defineStatusInfos(value).deliveryLabel,"small":""}})]}},{key:"total",fn:function({ value, }){return [(value.total_ttc !== null || value.total_ht !== null)?_c('div',[(value.total_ttc !== value.total_ht)?_c('strong',{staticClass:"all-taxes",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ttc', { prix: _vm.moneyFilter(value.total_ttc), }))}}):_vm._e(),_c('span',{staticClass:"duty-free",domProps:{"innerHTML":_vm._s(_vm.$t('prix.ht', { prix: _vm.moneyFilter(value.total_ht), }))}})]):_vm._e()]}},{key:"actions",fn:function({ value, }){return [(!_vm.isCommande && !value.verrouille)?[(_vm.isHorsMarche)?[(_vm.hasPerm('can_devis_to_cmd'))?_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('action.commander'),
              delay: { show: 800, },
            }),expression:"{\n              content: $t('action.commander'),\n              delay: { show: 800, },\n            }"}],attrs:{"variant":"solid","color":"secondary","icon":"left","disabled":_vm.isImpersonating},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-order', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('IconPanierFleche')]},proxy:true}],null,true)}):_vm._e(),_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('action.mettre-poubelle'),
              delay: { show: 800, },
            }),expression:"{\n              content: $t('action.mettre-poubelle'),\n              delay: { show: 800, },\n            }"}],attrs:{"variant":"special","color":"primary","icon":"left","size":"small","disabled":_vm.isImpersonating || (
              _vm.user.role !== 'Opérateur LDE'
              && value.contact !== _vm.user.contact.id
              && !_vm.hasPerm('can_transmettre_devis')
            )},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-delete', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function({ iconSize, }){return [_c('UilTrashAlt',{attrs:{"size":iconSize}})]}}],null,true)})]:(!_vm.hasPerm('can_view_as_maitre_compta'))?[(value.statut_affiche === 'expire')?_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('action.renouveler-le-devis'),
              delay: { show: 800, },
            }),expression:"{\n              content: $t('action.renouveler-le-devis'),\n              delay: { show: 800, },\n            }"}],attrs:{"variant":"solid","color":"secondary","icon":"left","disabled":_vm.isImpersonating},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-refresh', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function({ iconSize, }){return [_c('UilProcess',{attrs:{"size":iconSize}})]}}],null,true)}):[(_vm.hasPerm('can_transmettre_devis')
                && ['attente_validation', 'attente_soumission', ].includes(value.statut_affiche))?[_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.$t('action.valider'),
                  delay: { show: 800, },
                }),expression:"{\n                  content: $t('action.valider'),\n                  delay: { show: 800, },\n                }"}],attrs:{"variant":"solid","color":"primary","icon":"left","disabled":_vm.isImpersonating || value.statut_affiche === 'attente_soumission'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-validate', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function({ iconSize, }){return [_c('UilCheckCircle',{attrs:{"size":iconSize}})]}}],null,true)}),_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.$t('action.soumettre'),
                  delay: { show: 800, },
                }),expression:"{\n                  content: $t('action.soumettre'),\n                  delay: { show: 800, },\n                }"}],attrs:{"variant":"solid","color":"secondary","icon":"left","disabled":_vm.isImpersonating},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-submit', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function({ iconSize, }){return [_c('UilFileExport',{attrs:{"size":iconSize}})]}}],null,true)})]:(_vm.hasPerm('can_devis_to_cmd')
                && ['attente_approbation', 'attente_commande', ].includes(value.statut_affiche))?[_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.$t('action.approuver'),
                  delay: { show: 800, },
                }),expression:"{\n                  content: $t('action.approuver'),\n                  delay: { show: 800, },\n                }"}],attrs:{"variant":"solid","color":"primary","icon":"left","disabled":_vm.isImpersonating || value.statut_affiche === 'attente_commande'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-approve', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function({ iconSize, }){return [_c('UilCheckCircle',{attrs:{"size":iconSize}})]}}],null,true)}),_c('ButtonClassic',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.$t('action.commander'),
                  delay: { show: 800, },
                }),expression:"{\n                  content: $t('action.commander'),\n                  delay: { show: 800, },\n                }"}],attrs:{"variant":"solid","color":"secondary","icon":"left","disabled":_vm.isImpersonating},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('click-order', value)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('IconPanierFleche')]},proxy:true}],null,true)})]:_vm._e()]]:_vm._e()]:(_vm.isCommande && ['view_facture', 'view_expedition', ].some((perm) => _vm.hasPerm(perm)))?_c('ButtonPopover',{attrs:{"color":"primary","variant":"special","icon":"left","size":"small","placement":"bottom-right","trigger":"click"},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"left-icon",fn:function({ iconSize, }){return [_c('UilEllipsisV',{attrs:{"size":iconSize}})]}}],null,true),model:{value:(value._dropdownLinks),callback:function ($$v) {_vm.$set(value, "_dropdownLinks", $$v)},expression:"value._dropdownLinks"}},[_c('ul',{staticClass:"text-small"},[_c('li',[(_vm.hasPerm('view_facture'))?_c('router-link',{staticClass:"options-list-item-btn",attrs:{"to":{ name: 'commandes_factures_factures', query: { commande: value.id, }, }}},[_vm._v(" "+_vm._s(_vm.$t("commande.factures-liees"))+" ")]):_vm._e()],1),(_vm.hasPerm('view_expedition'))?_c('li',[_c('router-link',{staticClass:"options-list-item-btn",attrs:{"to":{ name: 'commandes_factures_expeditions', query: { commande: value.id, }, }}},[_vm._v(" "+_vm._s(_vm.$t("commande.expeditions-liees"))+" ")])],1):_vm._e()])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }