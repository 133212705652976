<template>
  <section class="devis-commandes-table">
    <TableClassic
      :columns="columns"
      :rows="rows"
      checkboxes
      route-name="listes_devis_devis_item"
      :active-sort="activeSort"
      @sort="$emit('sort', $event)"
    >
      <template #libelle="{ value, }">
        {{ value.libelle }}
      </template>
      <template #numero="{ value, }">
        {{ value.id_4d }}
      </template>
      <template #date="{ value, }">
        {{ value.date | moment($t("global.format-date")) }}
      </template>
      <template #quantite="{ value, }">
        {{ value.total_quantite }}
      </template>
      <template #statut="{ value, }">
        <TimelineLabel
          :tooltip="{ title: defineStatusInfos(value).tooltip, placement: 'top', }"
          :label="defineStatusInfos(value).label"
          :status="defineStatusInfos(value).status"
          :counter-label="defineStatusInfos(value).deliveryLabel"
          small
        />
      </template>
      <template #total="{ value, }">
        <div v-if="value.total_ttc !== null || value.total_ht !== null">
          <strong
            v-if="value.total_ttc !== value.total_ht"
            class="all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(value.total_ttc), })"
          />
          <span
            class="duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(value.total_ht), })"
          />
        </div>
      </template>
      <template #actions="{ value, }">
        <template v-if="!isCommande && !value.verrouille">
          <!-- Hors-marché -->
          <template v-if="isHorsMarche">
            <ButtonClassic
              v-if="hasPerm('can_devis_to_cmd')"
              v-tooltip="{
                content: $t('action.commander'),
                delay: { show: 800, },
              }"
              variant="solid"
              color="secondary"
              icon="left"
              :disabled="isImpersonating"
              @click.native.stop="$emit('click-order', value)"
            >
              <template #left-icon>
                <IconPanierFleche />
              </template>
            </ButtonClassic>
            <ButtonClassic
              v-tooltip="{
                content: $t('action.mettre-poubelle'),
                delay: { show: 800, },
              }"
              variant="special"
              color="primary"
              icon="left"
              size="small"
              :disabled="isImpersonating || (
                user.role !== 'Opérateur LDE'
                && value.contact !== user.contact.id
                && !hasPerm('can_transmettre_devis')
              )"
              @click.native.stop="$emit('click-delete', value)"
            >
              <template #left-icon="{ iconSize, }">
                <UilTrashAlt :size="iconSize" />
              </template>
            </ButtonClassic>
          </template>
          <!-- Marché non maître compta -->
          <template v-else-if="!hasPerm('can_view_as_maitre_compta')">
            <ButtonClassic
              v-if="value.statut_affiche === 'expire'"
              v-tooltip="{
                content: $t('action.renouveler-le-devis'),
                delay: { show: 800, },
              }"
              variant="solid"
              color="secondary"
              icon="left"
              :disabled="isImpersonating"
              @click.native.stop="$emit('click-refresh', value)"
            >
              <template #left-icon="{ iconSize, }">
                <UilProcess :size="iconSize" />
              </template>
            </ButtonClassic>
            <template v-else>
              <template
                v-if="hasPerm('can_transmettre_devis')
                  && ['attente_validation', 'attente_soumission', ].includes(value.statut_affiche)"
              >
                <ButtonClassic
                  v-tooltip="{
                    content: $t('action.valider'),
                    delay: { show: 800, },
                  }"
                  variant="solid"
                  color="primary"
                  icon="left"
                  :disabled="isImpersonating || value.statut_affiche === 'attente_soumission'"
                  @click.native.stop="$emit('click-validate', value)"
                >
                  <template #left-icon="{ iconSize, }">
                    <UilCheckCircle :size="iconSize" />
                  </template>
                </ButtonClassic>
                <ButtonClassic
                  v-tooltip="{
                    content: $t('action.soumettre'),
                    delay: { show: 800, },
                  }"
                  variant="solid"
                  color="secondary"
                  icon="left"
                  :disabled="isImpersonating"
                  @click.native.stop="$emit('click-submit', value)"
                >
                  <template #left-icon="{ iconSize, }">
                    <UilFileExport :size="iconSize" />
                  </template>
                </ButtonClassic>
              </template>
              <template
                v-else-if="hasPerm('can_devis_to_cmd')
                  && ['attente_approbation', 'attente_commande', ].includes(value.statut_affiche)"
              >
                <ButtonClassic
                  v-tooltip="{
                    content: $t('action.approuver'),
                    delay: { show: 800, },
                  }"
                  variant="solid"
                  color="primary"
                  icon="left"
                  :disabled="isImpersonating || value.statut_affiche === 'attente_commande'"
                  @click.native.stop="$emit('click-approve', value)"
                >
                  <template #left-icon="{ iconSize, }">
                    <UilCheckCircle :size="iconSize" />
                  </template>
                </ButtonClassic>
                <ButtonClassic
                  v-tooltip="{
                    content: $t('action.commander'),
                    delay: { show: 800, },
                  }"
                  variant="solid"
                  color="secondary"
                  icon="left"
                  :disabled="isImpersonating"
                  @click.native.stop="$emit('click-order', value)"
                >
                  <template #left-icon>
                    <IconPanierFleche />
                  </template>
                </ButtonClassic>
              </template>
            </template>
          </template>
        </template>
        <ButtonPopover
          v-else-if="isCommande && ['view_facture', 'view_expedition', ].some((perm) => hasPerm(perm))"
          v-model="value._dropdownLinks"
          color="primary"
          variant="special"
          icon="left"
          size="small"
          placement="bottom-right"
          trigger="click"
          @click.native.stop
        >
          <template #left-icon="{ iconSize, }">
            <UilEllipsisV :size="iconSize" />
          </template>
          <ul class="text-small">
            <li>
              <router-link
                v-if="hasPerm('view_facture')"
                class="options-list-item-btn"
                :to="{ name: 'commandes_factures_factures', query: { commande: value.id, }, }"
              >
                {{ $t("commande.factures-liees") }}
              </router-link>
            </li>
            <li v-if="hasPerm('view_expedition')">
              <router-link
                class="options-list-item-btn"
                :to="{ name: 'commandes_factures_expeditions', query: { commande: value.id, }, }"
              >
                {{ $t("commande.expeditions-liees") }}
              </router-link>
            </li>
          </ul>
        </ButtonPopover>
      </template>
    </TableClassic>
  </section>
</template>

<script>
import { ButtonPopover, moneyFilter } from "@lde/core_lde_vue";

import DevisCommandesTable from "@/mixins/mixinDevisCommandesTable";

import IconPanierFleche from "@/components/icons/IconPanierFleche.vue";

import {
  UilCheckCircle,
  UilTrashAlt,
  UilEllipsisV,
  UilFileExport,
} from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche les devis ou commandes de l'établissement courant qui n'est pas maître compta région.
 */
export default {
  name: "DevisCommandesTable",
  components: {
    ButtonPopover,
    UilCheckCircle,
    UilTrashAlt,
    UilEllipsisV,
    UilFileExport,
    IconPanierFleche,
  },
  mixins: [DevisCommandesTable],
  props: {
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Clé et sens du tri.
     */
    activeSort: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Variante commande.
     */
    isCommande: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché lors du tri du tableau principal.
     */
    "sort",
    /**
     * Déclenché au clic sur le bouton de suppression.
     */
    "click-delete",
    /**
     * Déclenché au clic sur le bouton d'actualisation.
     */
    "click-refresh",
    /**
     * Déclenché au clic sur le bouton de validation.
     */
    "click-validate",
    /**
     * Déclenché au clic sur le bouton de soumission.
     */
    "click-submit",
    /**
     * Déclenché au clic sur le bouton d'approbation.
     */
    "click-approve",
    /**
     * Déclenché au clic sur le bouton de passage en commande.
     */
    "click-order",
  ],
  data() {
    return {
      columns: [
        {
          key: "libelle",
          name: this.$t("liste.intitule"),
        },
        {
          key: "num_engagement",
          name: this.$t("liste.n-engagement"),
          sortKey: "num_engagement",
        },
        {
          key: "numero",
          name: this.$t("liste.numero"),
          sortKey: "id_4d",
        },
        {
          key: "date",
          name: this.$t("date.date"),
        },
        {
          key: "quantite",
          name: this.$t("general.quantite"),
          sortKey: "total_quantite",
        },
        {
          key: "statut",
          name: this.$t("general.statut"),
          notSortable: true,
        },
        {
          key: "total",
          name: this.$t("general.total"),
          sortKey: "total_ttc",
        },
        {
          key: "actions",
          notSortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isHorsMarche", "hasPerm", "user"]),
  },
  methods: {
    moneyFilter,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/devis_commandes_table.scss";
</style>
