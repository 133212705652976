import {
  PageContent,
  ButtonGroup,
  ButtonClassic,
  Pagination,
  InfiniteLoader,
  openInNewTab,
} from "@lde/core_lde_vue";

import ContextMenu from "@/components/ContextMenu.vue";
import InfosDotation from "@/components/listes_devis_commandes/InfosDotation.vue";
import DevisCommandesMaitreComptaTable from "@/components/table/DevisCommandesMaitreComptaTable.vue";
import DevisCommandesTable from "@/components/table/DevisCommandesTable.vue";
import ErrorPage from "@/components/layout/ErrorPage.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import TimelineLabel from "@/components/timeline/TimelineLabel.vue";

import IconPDF from "@/components/icons/IconPDF.vue";
import IconFileXls from "@/components/icons/IconFileXls.vue";
import IconChatFileExport from "@/components/icons/IconChatFileExport.vue";

import actionIsAllowed from "@/modules/devis_utils";
import { handleSort } from "@/modules/utils";

import config from "@/config";

import { mapGetters } from "vuex";

import { UilFilter, UilTimes, UilShareAlt } from "@iconscout/vue-unicons";

export default {
  components: {
    PageContent,
    ButtonGroup,
    ButtonClassic,
    Pagination,
    ContextMenu,
    InfosDotation,
    DevisCommandesMaitreComptaTable,
    DevisCommandesTable,
    ErrorPage,
    FilterSidebar,
    TimelineLabel,
    InfiniteLoader,
    IconPDF,
    UilFilter,
    UilTimes,
    UilShareAlt,
  },
  data() {
    return {
      isLoading: true,
      subArrayIsLoading: {}, // Objet car on a besoin de déterminer quel sous-tableau charge
      rows: [],
      budget: {},
      budgetType: "none",
      currentPage: 1,
      pageSize: this.$store.getters.hasPerm("can_view_as_maitre_compta") ? 12 : 24,
      totalRows: 0,
      subPageSize: 12,
      noResults: false,
      sort: {},
      sortChildren: {},
    };
  },
  computed: {
    ...mapGetters([
      "organismeActuel",
      "isHorsMarche",
      "hasPerm",
      "formalizeBudget",
      "isGE",
      "exportingStatsMarche",
      "hasMail",
      "user",
    ]),
    emptyData() {
      return !this.isLoading && !this.rows?.length;
    },
    actionsGlobales() {
      const actions = [];

      if (
        (this.hasPerm("can_export_devis") || this.hasPerm("can_export_commandes"))
        && (
          ["Valideur", "Donneur d'ordre", "Opérateur LDE"].includes(this.user.role)
        )
      ) {
        const type = this.$route.name.includes("listes_devis_devis") ? "devis" : "commandes";
        const options = [{
          title: this.$t("action.actions-sur-donnees-votre-etablissement"),
          actions: [{
            slug: "restant_livrer",
            label: this.$t("devis-commande.etat-des-commandes-en-cours"),
            icon: IconPDF,
          }],
        }];
        if (this.isGE) {
          options[0].actions.unshift(
            {
              slug: "export_xls",
              label: this.$t(`action.export-xls-${type}-annee-scolaire-en-cours`),
              icon: IconFileXls,
            },
          );
        }
        return options;
      }

      if (
        this.hasPerm("can_ask_export_stats")
        && this.hasPerm("can_view_as_maitre_compta")
        && ["Valideur hors-marché", "Donneur d'ordre"].includes(this.user.role)
        && this.isGE
        && this.hasMail
        && !this.exportingStatsMarche
      ) {
        actions.push({
          title: this.$t("action.actions-sur-donnees-ensemble-etablissements"),
          actions: [{
            slug: "ask_export_xls",
            label: this.$t("action.demande-export-xls-stats"),
            icon: IconChatFileExport,
          }],
        });
      }

      return actions;
    },
  },
  watch: {
    /**
     * Quand la recherche change dans la queryString
     */
    "$route.query": {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const toNumber = (value) => parseInt(value, 10);

          if (toNumber(newValue.page) !== toNumber(oldValue.page)) {
            this.currentPage = toNumber(newValue.page) || 1;
          }

          if (newValue.sort !== oldValue.sort) {
            this.sort.sortAscended = newValue.sort.charAt(0) !== "-";
            this.sort.key = newValue.sort.replace("-", "");
          }

          this.fetch();
        }
      },
    },
  },
  mounted() {
    const { page, sort } = this.$route.query;
    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    if (sort) {
      this.sort.sortAscended = sort.charAt(0) !== "-";
      this.sort.key = sort.replace("-", "");
    }
  },
  methods: {
    openInNewTab,
    handleSort,
    /**
     * Change la page du sous-tableau et effectue un tri.
     * @param {Object} ligne Ligne sur laquelle on effectue un changement de page.
     * @param {Number} page Numéro de page à afficher.
     * @param {Object} sort Clé et sens de tri.
     */
    handleSortChildren({ ligne, sort, page = 1 }) {
      this.$set(ligne, "_isLoading", true);
      document.querySelector(`.ligne-${ligne.id}:not(.child-row .ligne-${ligne.id})`).scrollIntoView();

      this.fetchChildren(ligne.id, sort, page)
        .then((res) => {
          this.$set(ligne, "_children", res);
          this.$set(ligne, "_isLoading", false);
        });
    },
    /**
     * Récupère le budget de l'organisme ou de la région.
     * @param {Boolean} isRegion si nous faisons la requête pour la région.
     * @returns {Promise}
     */
    fetchBudget(isRegion) {
      let toDispatch = "fetchBudgetRegion";
      if (!isRegion) {
        toDispatch = "fetchBudgetOrganisme";
      }
      return this.$store.dispatch(toDispatch, this.organismeActuel.id)
        .then((budget) => {
          this.budgetType = budget.type === "QUANTITE" ? "licences" : "euros";
          this.budget = budget;
        }).catch(() => {
          // this.$toast.error({ title: this.$t("dotation.erreur-lors-des-dotations") });
        });
    },
    /**
     * Déroule/replie les devis des établissements (donneur d'ordre/région).
     * Utilisé pour l'action "Déployer tous les établissements" du menu contextuel.
     * @param {Boolean} status true = expanded, false = collapse.
     */
    collapseElements(status) {
      this.rows.forEach((item) => {
        this.$set(item, "_expanded", status);
      });
    },
    /**
     * Génère un fichier XLS comprenant les devis ou les commandes de l'année scolaire en cours.
     * @param {String} type Devis ou commande.
     */
    exportXls(type) {
      this.openInNewTab(`${config.api.baseUrl}/${type}/export_${type}_annee_scolaire/`);
    },
    /**
     * Vérifie si on a le droit d'effectuer l'action donnée.
     * @param {String} button Action du bouton.
     * @param {Object} objet Informations du devis ou de la commande.
     */
    isAllowed(button, objet) {
      const me = objet.user_created === this.user.id;
      return actionIsAllowed(
        objet.statut_affiche,
        button,
        me,
      );
    },
  },
};
