<template>
  <section id="devis_commande_maitre_compta_table">
    <TableClassic
      :rows="rows"
      :columns="columns"
      :variant="isCommande ? 'commande' : 'devis'"
      :active-sort="activeSort"
      checkboxes
      @sort="$emit('sort', $event)"
    >
      <template #uai="{ value, }">
        {{ value.uai }}
      </template>
      <template #etablissement="{ value, }">
        <strong>{{ value.libelle }}</strong>
      </template>
      <template #budget-actuel="{ value, }">
        <div
          class="budget actuel"
          :class="defineBudgetClass(value, 'actuel')"
        >
          <UilBooks
            v-if="budgetType === 'licences'"
            size="22"
          />
          <UilEuroCircle
            v-else
            size="22"
          />
          <p>
            {{ $t("dotation.solde-actuel") }}
            <strong v-if="budgetType === 'licences'">
              <template v-if="value.budget.marche === 'Grand Est'">
                {{ value.budget.solde | spaceNumber }}
              </template>
              <template v-else>
                {{ value.budget.soldeAVenir | spaceNumber }}
              </template>
            </strong>
            <strong v-else>{{ value.budget.solde | moneyFilter }}{{ "\xa0" }}€</strong>
            {{ $t("general.sur") }}
            <strong v-if="budgetType === 'licences'">{{ value.budget.dotation | spaceNumber }}</strong>
            <strong v-else>{{ value.budget.dotation | moneyFilter }}{{ "\xa0" }}€</strong>
          </p>
        </div>
      </template>
      <template #nb="{ value, }">
        <Counter :value="value.counter" />
      </template>
      <template #total="{ value, }">
        <div>
          <strong
            v-if="value.total_ttc !== value.total_ht"
            class="all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(value.total_ttc), })"
          />
          <span
            class="duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(value.total_ht), })"
          />
        </div>
      </template>
      <template #nb-licences="{ value, }">
        <Counter :value="value.total_quantite" />
      </template>
      <template #budget-commande="{ value, }">
        <div
          class="budget commande"
          :class="defineBudgetClass(value, 'à venir')"
        >
          <UilBooks
            v-if="budgetType === 'licences'"
            size="22"
          />
          <UilEuroCircle
            v-else
            size="22"
          />
          <p>
            {{ $t("dotation.solde-a-venir") }}
            <strong>
              <template v-if="budgetType === 'licences'">
                {{ value.budget.soldeAVenir | spaceNumber }}
              </template>
              <template v-else>
                {{ value.budget.soldeAVenir | moneyFilter }}{{ "\xa0" }}€
              </template>
            </strong>
          </p>
        </div>
      </template>
      <template #actions="{ value, }">
        <template v-if="!isCommande">
          <template v-if="!isRouteSoumis && hasPerm('can_transmettre_devis')">
            <ButtonClassic
              v-tooltip="{
                content: $t('action.valider'),
                delay: { show: 800, },
              }"
              variant="solid"
              color="primary"
              icon="left"
              :disabled="isImpersonating"
              @click.native.stop="$emit('click-validate', value)"
            >
              <template #left-icon>
                <UilCheckCircle />
              </template>
            </ButtonClassic>
            <ButtonClassic
              v-tooltip="{
                content: $t('action.soumettre'),
                delay: { show: 800, },
              }"
              variant="solid"
              color="secondary"
              icon="left"
              :disabled="isImpersonating"
              @click.native.stop="$emit('click-submit', value)"
            >
              <template #left-icon>
                <UilFileExport />
              </template>
            </ButtonClassic>
          </template>
          <template v-else>
            <ButtonClassic
              v-tooltip="{
                content: $t('action.approuver'),
                delay: { show: 800, },
              }"
              variant="solid"
              color="primary"
              icon="left"
              :disabled="isImpersonating"
              @click.native.stop="$emit('click-approve', value)"
            >
              <template #left-icon="{ iconSize, }">
                <UilCheckCircle :size="iconSize" />
              </template>
            </ButtonClassic>
            <ButtonClassic
              v-tooltip="{
                content: $t('action.commander'),
                delay: { show: 800, },
              }"
              variant="solid"
              color="secondary"
              icon="left"
              :disabled="isImpersonating"
              @click.native.stop="$emit('click-order', value)"
            >
              <template #left-icon>
                <IconPanierFleche />
              </template>
            </ButtonClassic>
          </template>
        </template>
        <ButtonClassic
          v-tooltip="{
            content: !value._expanded ? $t('action.afficher-contenu') : $t('action.masquer-contenu'),
            delay: { show: 800, },
          }"
          class="dropdown"
          variant="special"
          color="primary"
          icon="left"
          size="small"
          @click.native.stop="$set(value, '_expanded', !value._expanded)"
        >
          <template #left-icon>
            <UilAngleDown v-if="!value._expanded" />
            <UilAngleUp v-else />
          </template>
        </ButtonClassic>
      </template>
      <template #child-row="{ value, }">
        <template v-if="!value._isLoading">
          <TableClassic
            :rows="value._children"
            :columns="childrenColumns"
            :variant="isCommande ? 'commande' : 'devis'"
            :active-sort="childrenActiveSort"
            checkboxes
            @sort="$emit('sort-children', { ligne: value, sort: $event, })"
          >
            <template #child-libelle="{ value: childValue, }">
              <strong>{{ childValue.libelle }}</strong>
            </template>
            <template #child-numero="{ value: childValue, }">
              {{ childValue.id_4d }}
            </template>
            <template #child-date="{ value: childValue, }">
              {{ childValue.date | moment($t("global.format-date")) }}
            </template>
            <template #child-quantite="{ value: childValue, }">
              {{ childValue.total_quantite }}
            </template>
            <template #child-statut="{ value: childValue, }">
              <TimelineLabel
                :tooltip="{ title: defineStatusInfos(childValue).tooltip, placement: 'top', }"
                :label="defineStatusInfos(childValue).label"
                :status="defineStatusInfos(childValue).status"
                :counter-label="defineStatusInfos(childValue).deliveryLabel"
                small
              />
            </template>
            <template #child-total="{ value: childValue, }">
              <div>
                <strong
                  v-if="childValue.total_ttc !== childValue.total_ht"
                  class="all-taxes"
                  v-html="$t('prix.ttc', { prix: moneyFilter(childValue.total_ttc), })"
                />
                <span
                  class="duty-free"
                  v-html="$t('prix.ht', { prix: moneyFilter(childValue.total_ht), })"
                />
              </div>
            </template>
            <template
              v-if="!isCommande"
              #child-actions="{ value: childValue, }"
            >
              <ButtonClassic
                v-if="childValue.statut_affiche === 'expire'"
                v-tooltip="{
                  content: $t('action.renouveler-le-devis'),
                  delay: { show: 800, },
                }"
                variant="solid"
                color="secondary"
                icon="left"
                :disabled="isImpersonating"
                @click.native.stop="$emit('click-refresh', childValue)"
              >
                <template #left-icon="{ iconSize, }">
                  <UilProcess :size="iconSize" />
                </template>
              </ButtonClassic>
              <template v-else>
                <template v-if="!isRouteSoumis && hasPerm('can_transmettre_devis')">
                  <ButtonClassic
                    v-tooltip="{
                      content: $t('action.valider'),
                      delay: { show: 800, },
                    }"
                    variant="solid"
                    color="primary"
                    icon="left"
                    :disabled="isImpersonating || childValue.statut_affiche === 'attente_soumission'"
                    @click.native.stop="$emit('click-validate', childValue)"
                  >
                    <template #left-icon>
                      <UilCheckCircle />
                    </template>
                  </ButtonClassic>
                  <ButtonClassic
                    v-tooltip="{
                      content: $t('action.soumettre'),
                      delay: { show: 800, },
                    }"
                    variant="solid"
                    color="secondary"
                    icon="left"
                    :disabled="isImpersonating"
                    @click.native.stop="$emit('click-submit', childValue)"
                  >
                    <template #left-icon>
                      <UilFileExport />
                    </template>
                  </ButtonClassic>
                </template>
                <template v-else>
                  <ButtonClassic
                    v-tooltip="{
                      content: $t('action.approuver'),
                      delay: { show: 800, },
                    }"
                    variant="solid"
                    color="primary"
                    icon="left"
                    :disabled="isImpersonating || childValue.statut_affiche === 'attente_commande'"
                    @click.native.stop="$emit('click-approve', childValue)"
                  >
                    <template #left-icon="{ iconSize, }">
                      <UilCheckCircle :size="iconSize" />
                    </template>
                  </ButtonClassic>
                  <ButtonClassic
                    v-tooltip="{
                      content: $t('action.commander'),
                      delay: { show: 800, },
                    }"
                    variant="solid"
                    color="secondary"
                    icon="left"
                    :disabled="isImpersonating"
                    @click.native.stop="$emit('click-order', childValue)"
                  >
                    <template #left-icon>
                      <IconPanierFleche />
                    </template>
                  </ButtonClassic>
                </template>
              </template>
            </template>
          </TableClassic>
          <div
            v-if="value.counter > subPageSize"
            class="pagination-container"
          >
            <Pagination
              v-model="value.currentPage"
              :total-rows="value.counter"
              :per-page="subPageSize"
              @change="(page) => $emit('sort-children', { ligne: value, sort: childrenActiveSort, page, })"
            />
          </div>
        </template>
        <div
          v-else
          class="loader"
        >
          <InfiniteLoader />
        </div>
      </template>
    </TableClassic>
  </section>
</template>

<script>
import {
  Counter,
  Pagination,
  moneyFilter,
  percentage,
} from "@lde/core_lde_vue";

import DevisCommandesTable from "@/mixins/mixinDevisCommandesTable";

import { couleurSelonTaux } from "@/modules/utils";

import {
  UilBooks,
  UilEuroCircle,
  UilCheckCircle,
  UilAngleDown,
  UilAngleUp,
  UilFileExport,
} from "@iconscout/vue-unicons";

import IconPanierFleche from "@/components/icons/IconPanierFleche.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les établissements du maître compta région et leurs devis ou commandes.
 */
export default {
  name: "DevisCommandesMaitreComptaTable",
  components: {
    Counter,
    Pagination,
    UilBooks,
    UilEuroCircle,
    UilCheckCircle,
    UilAngleDown,
    UilAngleUp,
    UilFileExport,
    IconPanierFleche,
  },
  mixins: [DevisCommandesTable],
  props: {
    /**
     * Variante commande.
     */
    isCommande: {
      type: Boolean,
      default: false,
    },
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Type du budget definissant quelles colonnes afficher.
     */
    budgetType: {
      type: String,
      validator: (value) => ["none", "euros", "licences"].includes(value),
      required: true,
    },
    /**
     * Définit le nombre d'éléments par page dans le tableau imbriqué.
     */
    subPageSize: {
      type: Number,
      default: undefined,
    },
    /**
     * Tri du tableau principal.
     */
    activeSort: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Tri du "sous-tableau".
     */
    childrenActiveSort: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    /**
     * Déclenché lors du tri du tableau principal.
     */
    "sort",
    /**
     * Déclenché au clic sur le bouton d'actualisation.
     */
    "click-refresh",
    /**
     * Déclenché au clic sur le bouton de validation.
     */
    "click-validate",
    /**
     * Déclenché au clic sur le bouton de soumission.
     */
    "click-submit",
    /**
     * Déclenché au clic sur le bouton d'approbation.
     */
    "click-approve",
    /**
     * Déclenché au clic sur le bouton de passage en commande.
     */
    "click-order",
    /**
     * Déclenché lors du tri ou au changement de page du sous-tableau.
     */
    "sort-children",
  ],
  computed: {
    ...mapGetters(["hasPerm", "isGE"]),
    isRouteSoumis() {
      return this.$route.name === "listes_devis_devis_soumis";
    },
    columns() {
      let colBudgetActuel = null;
      let colBudgetCommande = null;

      if (this.budgetType === "euros") {
        colBudgetActuel = {
          key: "budget-actuel",
          name: this.$t("devis-commande.solde-ttc-actuel"),
          sortKey: "budget_dotation",
        };
        if (!this.isCommande && this.isGE) {
          colBudgetCommande = {
            key: "budget-commande",
            name: this.$t("devis-commande.solde-ttc-si-commande"),
            sortKey: "budget_dotation",
          };
        }
      } else if (this.budgetType === "licences") {
        colBudgetActuel = {
          key: "budget-actuel",
          name: this.$t("devis-commande.ressources-actuelles"),
          sortKey: "budget_ressource",
        };
        if (!this.isCommande && this.isGE) {
          colBudgetCommande = {
            key: "budget-commande",
            name: this.$t("devis-commande.ressources-si-commande"),
            sortKey: "budget_ressource",
          };
        }
      }

      const columns = [
        {
          key: "uai",
          name: this.$t("general.uai"),
        },
        {
          key: "etablissement",
          name: this.$t("general.etablissement"),
          sortKey: "id_organisme",
        },
        colBudgetActuel,
        {
          key: "nb",
          name: this.$t("general.nbre"),
          notSortable: true,
        },
        {
          key: "total",
          name: this.isCommande
            ? this.$t("devis-commande.total-commandes")
            : this.$t("devis-commande.total-devis"),
          notSortable: true,
        },
      ];

      if (!this.isCommande) {
        if (!this.isRouteSoumis) {
          columns.push({
            key: "nb-licences",
            name: this.$t("general.nbre-licences"),
            notSortable: true,
          });
        } else if (colBudgetCommande) {
          columns.push(colBudgetCommande);
        }
      }

      columns.push({
        key: "actions",
        notSortable: true,
      });

      return columns;
    },
    childrenColumns() {
      const childrenColumns = [
        {
          key: "child-libelle",
          name: this.$t("liste.intitule"),
          sortKey: "libelle",
        },
        {
          key: "child-numero",
          name: this.$t("liste.numero"),
          sortKey: "id_4d",
        },
        {
          key: "child-date",
          name: this.$t("date.date"),
          sortKey: "date",
        },
      ];

      if (this.isCommande) {
        childrenColumns.push(
          {
            key: "child-total",
            name: this.$t("general.total"),
          },
          {
            key: "child-statut",
            name: this.$t("general.statut"),
            notSortable: true,
          },
        );
      } else {
        childrenColumns.push(
          {
            key: "child-quantite",
            name: this.$t("general.quantite"),
            sortKey: "total_quantite",
          },
          {
            key: "child-statut",
            name: this.$t("general.statut"),
            sortKey: "se_etat_ordering",
          },
          {
            key: "child-total",
            name: this.$t("general.total"),
            sortKey: "total_ttc",
          },
          {
            key: "child-actions",
            notSortable: true,
          },
        );
      }

      return childrenColumns;
    },
  },
  methods: {
    moneyFilter,
    /**
     * Définit la classe selon le budget actuel de la ligne.
     * @param {Object} ligne Infos de la ligne.
     * @param {String} budgetType Budget actuel ou à venir.
     * @returns {String} Classe selon le budget.
     */
    defineBudgetClass(ligne, budgetType) {
      const { budget } = ligne;
      if (!budget || !Object.keys(budget).length) {
        return "empty";
      }

      let percent = null;

      if (budgetType === "actuel" && ![null, 0].includes(budget.solde)) {
        const isGE = budget.marche === "Grand Est";
        percent = percentage(isGE ? budget.solde : budget.soldeAVenir, budget.dotation);
        return couleurSelonTaux(percent, true);
      }

      if (budgetType === "à venir" && ![null, 0].includes(budget.solde)) {
        percent = percentage(budget.soldeAVenir, budget.dotation);
        return couleurSelonTaux(percent, true);
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/devis_commandes_maitre_compta.scss";
</style>
