import {
  PageContent,
  ButtonClassic,
  InfiniteLoader,
  ucFirst,
} from "@lde/core_lde_vue";

import TableClassic from "@/components/table/TableClassic.vue";
import TimelineLabel from "@/components/timeline/TimelineLabel.vue";

import typeMarcheTraduction from "@/modules/trad_utils";

import {
  UilFilter,
  UilTimes,
  UilShareAlt,
  UilProcess,
} from "@iconscout/vue-unicons";

export default {
  components: {
    PageContent,
    ButtonClassic,
    TableClassic,
    TimelineLabel,
    InfiniteLoader,
    UilFilter,
    UilTimes,
    UilShareAlt,
    UilProcess,
  },
  methods: {
    /**
     * Définit le statut de la ligne.
     * @param {Object} ligne Infos de la ligne.
     * @returns {Object} Statut de la ligne et son label.
     */
    defineStatusInfos(ligne) {
      let status = "valid";
      let label = null;
      let deliveryLabel = null;
      let tooltip = null;

      switch (ligne.statut_affiche) {
        case "transferee":
          label = this.$t("timeline.transferee");
          status = "transferee";
          break;
        case "expire":
          label = this.$t("timeline.expire");
          status = "expire";
          tooltip = this.$t("timeline.ce-devis-est-expire");
          break;
        case "attente_modification":
          label = this.$t("timeline.a-modifier");
          status = "warning";
          tooltip = this.$t("timeline.devis-doit-etre-modifie");
          break;
        case "refus":
          label = this.$t("timeline.rejete");
          status = "error";
          tooltip = this.$t("timeline.ce-devis-a-ete-refuse");
          break;
        case "annulee":
          label = this.$t("timeline.annulee");
          status = "error";
          break;
        case "attente_selection":
          label = this.$t("timeline.liste");
          tooltip = this.$t("devis-commande.tooltip.liste-creee-doit-etre-transformee-devis");
          break;
        case "attente_validation":
          label = this.$t("timeline.devis-cree");
          tooltip = this.$t("devis-commande.tooltip.devis-cree-doit-etre-valide");
          break;
        case "attente_soumission":
          label = this.$t("timeline.validation-etablissement");
          tooltip = typeMarcheTraduction().devisCommandeTooltipDevisValideAttenteSoumission;
          break;
        case "attente_approbation":
          label = typeMarcheTraduction().timelineSoumissionRegion;
          tooltip = typeMarcheTraduction().devisCommandeTooltipDevisSoumisEnAttenteApprobation;
          break;
        case "attente_commande":
          label = this.isHorsMarche
            ? this.$t("timeline.validation-etablissement")
            : typeMarcheTraduction().timelineApprobationRegion;
          tooltip = !this.isHorsMarche
            ? typeMarcheTraduction().devisCommandeTooltipDevisApprouveEnAttenteCommande
            : this.$t("devis-commande.tooltip.devis-valide-en-attente-commande");
          break;
        case "attente_expedition":
          label = this.$tc("timeline.commande");
          tooltip = this.$t("devis-commande.tooltip.commande-enregistree-lde-attente-traitement");
          break;
        case "attente_livraison":
          if (ligne.has_num) { // Pour les articles physiques, il n'y a pas de statut de livraison pour l'instant
            label = this.$t("timeline.livraison");
            deliveryLabel = `${ligne.nb_livree}/${ligne.total_quantite}`;
          } else {
            label = this.$t("timeline.expedition");
            deliveryLabel = `${ligne.nb_expediee}/${ligne.total_quantite}`;
          }
          tooltip = this.$t("devis-commande.tooltip.commande-en-cours-traitement-sera-deposee");
          break;
        case "livree":
          if (ligne.has_num) { // Pour les articles physiques, il n'y a pas de statut de livraison pour l'instant
            label = this.$t("timeline.livre");
            deliveryLabel = `${ligne.nb_livree}/${ligne.total_quantite}`;
          } else {
            label = this.$t("timeline.expedie");
            deliveryLabel = `${ligne.nb_expediee}/${ligne.total_quantite}`;
          }
          tooltip = this.$t("devis-commande.tooltip.commande-traitee-editeur-deposee");
          break;
        default:
          label = ucFirst(ligne.statut_affiche.replace("attente_", ""));
          status = ligne.statut_affiche.indexOf("attente_") > -1 ? "awaiting" : null;
          break;
      }

      return {
        label,
        status,
        deliveryLabel,
        tooltip,
      };
    },
  },
};
