export default {
  data() {
    return {
      allFilters: [],
    };
  },
  computed: {
    activeFilters() {
      const query = this.$route.query;
      return Object.entries(query)
        .filter(([key]) => key !== "page" && !key.option?.split(",").every((opt) => opt.isNaN()))
        .map(([key, value]) => ({ slug: key, options: value.toString().split(",").map(Number) }));
    },
  },
};
