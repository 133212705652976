import { openInNewTab } from "@lde/core_lde_vue";
import config from "@/config";

export default {
  methods: {
    openInNewTab,
    /**
     * Télécharge le ou les devis sélectionné(s) au format PDF.
     * @param {String} type Type de l'objet courant ("devis" ou "facture").
     */
    downloadPdf(type) {
      // Si le type n'est pas correct, on affiche une erreur dans la console
      if (!["devis", "facture", "commande"].includes(type)) {
        console.error(`Erreur: downloadPdf - Le type ${type} n'existe pas.`);
        return;
      }
      const urlPdf = `${config.api.baseUrl}/${type}/multi_pdf/?ids=${this.selections.map((s) => s.id)}`;
      const nbValid = this.selections.filter((s) => s.url_pdf !== "").length;
      const nbInvalid = this.selections.length - nbValid;
      let toast = {};
      // Si aucun objet n'est sélectionné (cas normalement impossible car bouton désactivé, donc Easter Egg :D)
      if (!this.selections.length) {
        this.$toast.error({
          title: "Je laisse ça là&nbsp;:<br /> \"Le fait d'accéder ou de se maintenir, frauduleusement, dans tout ou "
                + "partie d'un système de traitement automatisé de données est puni de deux ans d'emprisonnement et de "
                + "30&nbsp;000 euros d'amende.\"",
          content: "<img style='position: absolute;top: 50vh;right: 50vw;transform: translate(50%, -50%);width: 200%'"
                  + "src='https://c.tenor.com/W5LKL_GJEPIAAAAC/vous-ne-passerez-pas-le-seigneur-des-anneaux.gif' />",
          autoHideDelay: 10,
        });
      } else if (this.selections.length >= 1) {
        // Si un ou plusieurs objets sélectionnés
        // Si au moins un objet a un URL
        if (nbValid > 0) {
          // Si au moins un objet n'a pas d'URL
          if (nbInvalid > 0) {
            if (type === "devis") {
              toast = {
                title: this.$t("devis.telechargement-certains-devis-impossible"),
                content: this.$t("devis.autres-devis-telecharges"),
              };
            } else if (type === "commande") {
              toast = {
                title: this.$t("commande.telechargement-certaines-commande-impossible"),
                content: this.$t("commande.autres-commandes-telechargees"),
              };
            } else if (type === "facture") {
              toast = {
                title: this.$t("facture.telechargement-certaines-factures-impossible"),
                content: this.$t("facture.autres-facture-telechargees"),
              };
            }
            this.$toast.warning(toast);
          } else {
            // Si tous les objets ont une URL
            if (type === "devis") {
              toast.title = this.$tc("devis.devis-selectionnes-telecharges", this.selections.length);
            } else if (type === "commande") {
              toast.title = this.$tc("commande.commandes-selectionnees-telechargees", this.selections.length);
            } else if (type === "facture") {
              toast.title = this.$tc("facture.factures-selectionnees-telechargees", this.selections.length);
            }
            this.$toast.success(toast);
          }
          this.openInNewTab(urlPdf);
        } else {
          // Si aucun objet n'a d'URL
          if (type === "devis") {
            toast.title = this.$tc("devis.telechargement-devis-impossible", this.selections.length);
          } else if (type === "commande") {
            toast.title = this.$tc("commande.telechargement-commande-impossible", this.selections.length);
          } else if (type === "facture") {
            toast.title = this.$tc("facture.telechargement-facture-impossible", this.selections.length);
          }
          this.$toast.warning(toast);
        }
      } else if (!this.selections[0].url_pdf) {
        // Si un seul objet sélectionné et si l'objet n'a pas d'URL
        if (type === "devis") {
          toast.title = this.$tc("devis.telechargement-devis-impossible", this.selections.length);
        } else if (type === "commande") {
          toast.title = this.$tc("commande.telechargement-commande-impossible", this.selections.length);
        } else if (type === "facture") {
          toast.title = this.$tc("facture.telechargement-facture-impossible", this.selections.length);
        }
        this.$toast.warning(toast);
      } else {
        // Si un seul objet sélectionné et si l'objet a un URL
        if (type === "devis") {
          toast.title = this.$tc("devis.devis-selectionnes-telecharges", this.selections.length);
        } else if (type === "commande") {
          toast.title = this.$tc("commande.commandes-selectionnees-telechargees", this.selections.length);
        } else if (type === "facture") {
          toast.title = this.$tc("facture.factures-selectionnees-telechargees", this.selections.length);
        }
        this.$toast.success(toast);
        this.openInNewTab(urlPdf);
      }
    },
  },
};
