<template>
  <div
    id="infos_dotation"
    class="flex-vcenter text-small"
    :class="{ 'shared-budget': isSharedBudget, }"
  >
    <div class="etablissement-name">
      <div>
        <IconEstablishment />
        <strong>{{ organismeActuel.nom }}</strong>
      </div>
      <div
        v-tooltip="progressTooltip"
        class="flex-vcenter"
        :class="progress.customClass"
      >
        <component
          :is="progress.icon"
          size="16"
        />
        <strong>{{ progress.label }}</strong>
        <ProgressBar
          :value="progress.value"
          :total="progress.total"
          :custom-class="progress.customClass"
        />
      </div>
    </div>

    <div class="etablissement-data flex-vcenter">
      <div
        v-for="({ label, value, customClass, }, index) in objectInfos"
        :key="index"
        class="bloc-infos"
        :class="customClass"
      >
        <span class="first-line">{{ label }}</span>
        <strong v-if="budgetType === 'euros'">{{ value | moneyFilter }}{{ "\xa0" }}€</strong>
        <strong v-else>{{ value | spaceNumber }}</strong>
      </div>

      <div class="bloc-infos">
        <button
          class="button flex-vcenter"
          @click="$modal.show('modal_budget_explanation')"
        >
          <UilInfoCircle size="16" />
        </button>
      </div>
    </div>
    <ModalBudgetExplanation
      :budget-infos="objectInfos"
      :budget-type="budgetType"
      :progress="progress"
    />
  </div>
</template>

<script>
import {
  ProgressBar,
  ButtonClassic,
  percentage,
  moneyFilter,
} from "@lde/core_lde_vue";

import IconEstablishment from "@/components/icons/IconEstablishment.vue";

import { UilEuroCircle, UilBooks, UilInfoCircle } from "@iconscout/vue-unicons";

import { couleurSelonTaux } from "@/modules/utils";

import typeMarcheTraduction from "@/modules/trad_utils";

import { mapGetters } from "vuex";

import ModalBudgetExplanation from "@/components/modals/ModalBudgetExplanation.vue";

/**
 * Affiche les informations du budget de l'établissement courant.
 */
export default {
  name: "InfosDotation",
  components: {
    ProgressBar,
    ButtonClassic,
    ModalBudgetExplanation,
    IconEstablishment,
    UilInfoCircle,
  },
  props: {
    /**
     * Informations sur le budget.
     */
    budget: {
      type: Object,
      required: true,
    },
    /**
     * Type du budget.
     */
    budgetType: {
      type: String,
      validator: (value) => ["none", "euros", "licences"].includes(value),
      required: true,
    },
  },
  data() {
    return {
      objectInfos: [],
      progress: {},
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isHorsMarche", "isSharedBudget"]),
    budgetStatus() {
      if (this.budget.dotation !== 0 || this.budget.dotation !== null) {
        const percent = percentage(this.budget.enCours, this.budget.dotation);

        return couleurSelonTaux(percent);
      }
      return null;
    },
    restantStatus() {
      if (this.budget.soldeAVenir !== 0 || this.budget.soldeAVenir !== null) {
        const percent = percentage(this.budget.soldeAVenir, this.budget.dotation);

        return couleurSelonTaux(percent, true);
      }
      return null;
    },
    helpSoldeAVenir() {
      const type = this.isHorsMarche ? this.$tc("dotation.devis-non-valides", 2) : this.$t("timeline.devis-soumis");
      const dispo = this.isHorsMarche ? this.$t("produit.disponible") : this.$tc("dotation.alloue", 0);
      let text = this.$t("dotation.solde-a-venir-help-1", { dispo, type });
      text += "<br />";
      if (this.budget.soldeAVenir >= 0) {
        text += this.$t("dotation.solde-a-venir-si-negatif");
      } else {
        text += this.$t("dotation.solde-a-venir-si-positif");
      }
      return text;
    },
    progressTooltip() {
      let { value, total } = this.progress;
      if (this.budgetType === "euros") {
        value = `${this.moneyFilter(value)}&nbsp;€`;
        total = `${this.moneyFilter(total)}&nbsp;€`;
      }
      return {
        content: `${value} / ${total}`,
        placement: "bottom",
        delay: { show: 800 },
        html: true,
      };
    },
  },
  mounted() {
    const {
      enCours,
      dotation,
      commande,
      devisTransmis,
      devisATransmettre,
      soldeAVenir,
    } = this.budget;

    this.objectInfos = [
      {
        label: this.isHorsMarche ? this.$t("produit.disponible") : this.$tc("dotation.alloue", 0),
        value: dotation,
        help: this.isHorsMarche
          ? this.$t("dotation.hm-dispo-help")
          : typeMarcheTraduction().dotationAlloueHelp,
      },
      {
        label: this.$tc("commande.commandes", 0),
        value: commande,
        help: this.isHorsMarche ? this.$t("dotation.hm-commande-help") : this.$t("dotation.commande-help"),
      },
      {
        label: this.isHorsMarche ? this.$t("dotation.devis-valides") : this.$t("timeline.devis-soumis"),
        value: devisTransmis,
        help: this.isHorsMarche
          ? this.$t("dotation.devis-valides-help")
          : typeMarcheTraduction().dotationDevisTransmisHelp,
      },
      {
        label: soldeAVenir >= 0 ? this.$t("dotation.restant") : this.$t("dotation.depassement"),
        value: soldeAVenir,
        help: this.helpSoldeAVenir,
        customClass: this.restantStatus,
      },
      {
        label: this.isHorsMarche ? this.$tc("dotation.devis-non-valides", 2) : this.$t("dotation.devis-non-soumis"),
        value: devisATransmettre,
        help: this.isHorsMarche
          ? this.$t("dotation.devis-non-valides-help")
          : typeMarcheTraduction().dotationDevisNonSoumisHelp,
        customClass: "gray",
      },
    ];
    this.progress = {
      value: enCours,
      total: dotation,
      icon: this.budgetType === "euros" ? UilEuroCircle : UilBooks,
      label: this.budgetType === "euros" ? this.$t("devis-commande.budget") : this.$tc("produit.licence"),
      customClass: this.budgetStatus,
      tooltip: this.progressTooltip,
    };

    // eMLS pour IDF
    if (this.isSharedBudget) {
      this.objectInfos.splice(2, 0, {
        label: "dont ressources",
        value: this.budget.emls_commande,
      });
      this.objectInfos.splice(4, 0, {
        label: "dont ressources",
        value: this.budget.emls_valide,
      });
    }
  },
  methods: {
    moneyFilter,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/listes_devis_commandes/infos_dotation.scss";
</style>
