<template>
  <Modal id="modal_budget_explanation">
    <template #title>
      {{ $t('dotation.informations-liees-a-la-dotation') }}
    </template>
    <template #subtitle>
      {{ isHorsMarche
        ? $t('dotation.outil-permet-suivre-budget')
        : typeMarcheTraduction().dotationOutilPermetSuivreBudgetAlloue }}
    </template>
    <template #content>
      <ul>
        <li
          v-for="({ label, value, help, customClass, }, index) in budgetInfos"
          :key="index"
          class="bloc-infos"
        >
          <div
            class="infos text-regular"
            :class="customClass"
          >
            <span class="text-regular">{{ label }}</span>
            <strong v-if="budgetType === 'euros'">{{ value | moneyFilter }}{{ "\xa0" }}€</strong>
            <strong v-else>{{ value | spaceNumber }}</strong>
          </div>
          <p
            class="explanation text-medium"
            v-html="help"
          />
        </li>
        <li
          v-if="Object.keys(progress).length > 0"
          class="bloc-infos budget-progress"
        >
          <div
            v-tooltip="{ content: progress.progressTooltip, }"
            class="etab-name flex-vcenter"
            :class="progress.customClass"
          >
            <component
              :is="progress.icon"
              size="24"
            />
            <strong class="text-regular">{{ progress.label }}</strong>
            <ProgressBar
              :value="progress.value"
              :total="progress.total"
              :custom-class="progress.customClass"
            />
          </div>
          <div class="explanation text-medium">
            <span v-html="$t('dotation.couleur-budget-peut-changer')" />
            <ul>
              <li>
                <span class="positive">{{ $t('general.vert') }}</span>
                <span v-html="progressHelpText.positive" />
              </li>
              <li>
                <span class="warning">{{ $t('general.orange') }}</span>
                <span v-html="progressHelpText.warning" />
              </li>
              <li>
                <span class="negative">{{ $t('general.rouge') }}</span>
                <span v-html="progressHelpText.negative" />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </template>
    <template #footer>
      <ButtonClassic
        variant="solid"
        color="secondary"
        :label="$t('general.j-ai-compris')"
        icon="right"
        @click="hide()"
      >
        <template #right-icon>
          <UilThumbsUp />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { ButtonClassic, Modal, ProgressBar } from "@lde/core_lde_vue";

import { UilThumbsUp } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

import typeMarcheTraduction from "@/modules/trad_utils";

/**
 * Modale d'explication des informations sur le budget.
 */
export default {
  name: "ModalBudgetExplanation",
  components: {
    Modal,
    ProgressBar,
    ButtonClassic,
    UilThumbsUp,
  },
  props: {
    /**
     * Informations du budget.
     */
    budgetInfos: {
      type: Array,
      required: true,
    },
    /**
     * Type du budget.
     */
    budgetType: {
      type: String,
      validator: (value) => ["euros", "licences"].includes(value),
      required: true,
    },
    /**
     * Information sur la barre de progression.
     */
    progress: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["isHorsMarche"]),
    progressHelpText() {
      return {
        positive: this.isHorsMarche ? this.$t("dotation.hm-progress-vert") : this.$t("dotation.progress-vert"),
        warning: this.isHorsMarche ? this.$t("dotation.hm-progress-orange") : this.$t("dotation.progress-orange"),
        negative: this.$t("dotation.progress-rouge"),
      };
    },
  },
  methods: {
    typeMarcheTraduction,
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide("modal_budget_explanation");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_budget_explanation.scss";
</style>
